import { loadPage } from '@core/page-loader';
import pagesMap from './configs/pages-map';
import '@magento';
import './styles/style.less';

const initApp = async () => {
    try {
        await loadPage(pagesMap, window.context.page);
        await loadPage(pagesMap, 'default');
    } catch (error) {
        console.error(error);
    }
};

initApp();
