export default {
    default: () => import('../pages/default'),
    catalog_category_view: () => import('../pages/listing'),
    catalogsearch_result_index: () => import('../pages/listing'),
    checkout_index_index: () => import('../pages/checkout'),
    catalog_product_view: () => import('../pages/product'),
    blog_index_index: () => import('../pages/blog'),
    blog_category_view: () => import('../pages/blog'),
    wishlist_index_index: () => import('../pages/account'),
    amasty_rma_guest_login: () => import('../pages/rma'),
    amasty_rma_account_newreturn: () => import('../pages/rma-account'),
    amasty_rma_guest_newreturn: () => import('../pages/rma-account'),
    customer_address_form: () => import('../pages/account'),
    checkout_onepage_success: () => import('../pages/checkout-success'),
    checkout_cart_index: () => import('../pages/cart'),
    lookbook_index_index: () => import('../pages/inspirations'),
    lookbook_category_view: () => import('../pages/inspirations'),
    lookbook_profile_view: () => import('../pages/inspirations')
};
